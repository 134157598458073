<template>
  <div>
    <v-card class="mb-5">
      <v-card-title>
        Respondents
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete
              :items="projects"
              item-text="name"
              item-value="_id"
              autocomplete
              v-model="searchCriteria.projectId"
              label="Choose Project"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-btn color="primary" @click="filterRespondents">Select</v-btn>
      </v-card-text>
    </v-card>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="respondents"
      item-key="_id"
      :search="search"
      show-select
      class="elevation-1"
    >
      <!-- <template v-slot:items="props"> -->
     <template v-slot="{ items }">
        <td>
          <v-checkbox
           v-model="items.selected"
            primary
            hide-details
            :disabled="items.status =='Disqualified'"
          ></v-checkbox>
        </td>
      </template>

      <template v-slot:item.respondent_id="{ item }">
        <td>
          <a
            :href="'/buyer/manageRespondents/' + item._id"
            target="_blank"
          >{{ item.respondent_id }}</a>
        </td>
      </template>
      
      <v-alert v-slot:no-results :value="true" color="error" icon="warning">No results found.</v-alert>
    </v-data-table>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{snackText}}
      <v-btn @click="snack = false">Close</v-btn>
    </v-snackbar>
    <div class="mt-4">
      <v-btn
        @click="disqualifyRespondant(selected)"
        :disabled="selected.length == 0"
        color="primary"
      >Disqualify</v-btn>
    </div>

  </div>
</template>


<script>
import RestResource from "@/services/dataServiceBuyer.js";
const service = new RestResource();
export default {
  data() {
    return {
      searchCriteria: {},
      dialog: false,
      search: "",
      props: [],
      selected: [],
      respondents: [],
      projects: [],
      snack: false,
      snackText: "",
      snackColor: "",
      headers: [
        {
          text: "Project Name",
          align: "left",
          sortable: false,
          value: "projectName"
        },
        { text: "Campaign Id", value: "campaign_id", sortable: false },
        { text: "Respondent Id", value: "respondent_id", sortable: false },
        { text: "Status", value: "status", sortable: true }
      ]
    };
  },

  mounted() {
    this.loadProjects();
  },

  methods: {    
    findAndReplaceBulk(arr, value, replaceValue) {
      for (var i = 0; i < arr.length; i++) {
        this.findAndReplace(arr[i], value, replaceValue);
      }
    },

    findAndReplace(object, value, replaceValue) {
      for (var x in object) {
        if (typeof object[x] == "object") {
          this.findAndReplace(object[x], value, replaceValue);
        }
        if (object[x] == value) {
          object["status"] = replaceValue;
        }
      }
    },

    async disqualifyRespondant(res) {
      try {
        this.$setLoader();
        service.disqualifyRespondent(res).then(r => {
          r;
          this.$disableLoader();
          this.findAndReplaceBulk(res, "Complete", "Disqualified");
          this.selected = [];
          this.snack = true;
          this.snackColor = "primary";
          this.snackText = "Respondent Disqualified";
        });
      } catch (error) {
        alert(error);
      }
    },

    loadRespondants() {
      this.$setLoader();
      service.fetchRespondents().then(r => {
        this.respondents = r.data;
        this.$disableLoader();
      });
    },

    loadProjects() {
      service.fetchProjects().then(r => {
        this.projects = r.data;
      });
    },

    filterRespondents() {
      this.$setLoader();
      service.fetchRespondents(this.searchCriteria).then(r => {
        this.respondents = r.data;
        this.$disableLoader();
      });
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: black;
}
</style>


